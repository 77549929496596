import { Label } from "./ui/label";
import { Input } from "./ui/input";
import Modal from 'react-modal';
import { useState } from "react";
import { useOasisBackend } from "../hooks/useOasisBackend";
import toast from 'react-hot-toast';
import { IconX } from "@tabler/icons-react";

interface APIWaitlistModalProps {
    modalIsOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const APIWaitlistModal = ({modalIsOpen, setIsOpen}: APIWaitlistModalProps) => {
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const db = useOasisBackend()

    const submitToWaitList = async () => {
        if(!email){
            toast.error('Please enter your email address');
            return;
        }
        if(!name){
            toast.error('Please enter your name');
            return;
        }
        const response = await db.endpoints.api.apiWaitlistCreate({email: email, name: name, company: company, role: role});
        if(response.status !== 201) {
            toast.error('Error Submitting Email To Waitlist');
            return;
        }
        setIsOpen(false)
        toast('Email Submitted To Waitlist!');
    }

    return(
        <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => (setIsOpen(false))}
      style={{
        overlay: {
          background: 'transparent',
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          border: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          height: 'auto',
          inset: 'auto',
          background: 'transparent',
          // Mobile media query
          // @ts-ignore
          '@media (max-width: 768px)': {
            padding: '1rem',
          },
          zIndex: 1000
        }
      }}
    >

        <div className="bg-white p-16 rounded-xl">
            <div>
                <button onClick={() => setIsOpen(false)} className="absolute top-10 right-10">
                    <IconX />
                </button>
            </div>
            <div className="text-center text-2xl font-bold">Join the API Waitlist</div>
            <div className="text-center text-sm">We will notify you when the API is ready for use</div>
            <div className="flex flex-col gap-4 p-6">
                <Label>Email</Label>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                <Label>Name</Label>
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your name"
                />
                <Label>Company</Label>
                <Input
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="Your company"
                />
                <Label>Role</Label>
                <Input
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    placeholder="Your role"
                />
                <button onClick={submitToWaitList} className="bg-pitchDeckTeal text-white font-bold px-6 py-2">
                    Join Waitlist
                </button>
            </div>
        </div>
    </Modal>
    )
}

export default APIWaitlistModal;