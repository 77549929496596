/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import axios from '../api/axios';
import { useNavigate, useParams , useLocation } from 'react-router-dom';
import Eyes from '../components/Eyes';
import { css, keyframes } from '@emotion/react';
import { Form, Submission, SubmissionField, FormField} from '../components/interfaces'; // Assuming interfaces are in a file named interfaces.ts


import Markdown from 'react-markdown'

const FillForm: React.FC = () => {
    const { formId } = useParams();  // Get formId from the URL
    const [message, setMessage] = useState('');
    const location = useLocation(); 
    const [aiMessage, setAiMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [handledInitialSubmit, setHandledInitialSubmit] = useState(false);
    const [startNewConversation, setStartNewConversation] = useState(false);
    const [formTitle, setFormTitle] = useState(null);
    const [form, setForm] = useState<Form | null>(null);
    const [submission, setSubmission] = useState<Submission | null>(null);

    const navigate = useNavigate();
    
    const is_testing = new URLSearchParams(location.search).get('test') === 'true'; 

    const [conversation_id, setConversationId] = useState(() => {
        const savedData = localStorage.getItem('conversation_data');
        if (savedData) {
            const { conversation_id, form_id: savedFormId } = JSON.parse(savedData);
            // If the form_id is different, reset conversation_id
            if (savedFormId !== formId) {
                return null;
            }
            return conversation_id;
        }
        return null;
    });


    useEffect(() => {
        if (is_testing && conversation_id) {
            const fetchSubmission = async () => {
                const response = await axios.get(`/get-single-submission/${conversation_id}`);
                setSubmission(response.data);
                console.log(response.data);
            };
            fetchSubmission();
        }
    }, [conversation_id, is_testing, aiMessage]);

    useEffect(() => {
        const fetchFormDetails = async () => {
          try {
            if (is_testing) {
              const response = await axios.get(`/get-form/${formId}`);
              setForm(response.data);
              setFormTitle(response.data.title);
            } else {
              const response = await axios.get(`/get-form-title/${formId}`);
              setFormTitle(response.data.title);
            }
          } catch (error) {
            console.log('Error fetching form details:', error);
          }
        };
        fetchFormDetails();
      }, [formId, is_testing]);

    useEffect(() => {
        // focus textarea if not loading
        if (!loading && inputRef.current) {
            inputRef.current.focus();
        }
    },[loading]);

    useEffect(() => {
        if (conversation_id) {
            localStorage.setItem(
                'conversation_data',
                JSON.stringify({ conversation_id, form_id: formId })
            );
        }
    }, [conversation_id, formId]);



    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleSubmit(new Event('submit') as unknown as React.FormEvent);
            }
            // if no focus on input, focus on it when user starts typing
            if (inputRef.current) {
                inputRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('click', () => { if (inputRef.current) inputRef.current.focus(); });

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [message, conversation_id]);

    // Trigger handleSubmit when conversation_id is null (reset) and the user starts a new conversation
    useEffect(() => {
        if (conversation_id === null && startNewConversation) {
            handleSubmit();
            setStartNewConversation(false); // Reset the flag after handling the submission
        }
    }, [conversation_id, startNewConversation]);

    const handleFeistyHome = () => {
        navigate(`/`);
    };

    const handleSubmit = async (e?: React.FormEvent) => {
        e?.preventDefault();  // Prevent form's default submit behavior, but only if e is provided
        setLoading(true);
        setAiMessage(''); // Clear AI message and show animation placeholder
        setMessage(''); // Clear the input field
    
        try {
            const response = await axios.post(`/conversation/`, {'form_id': formId, 'conversation_id': conversation_id, 'message': message, 'is_testing' : is_testing });
            const data = response.data;
            setConversationId(data.conversation_id);
    
            setAiMessage(data.new_message); // Update AI message with response
        } catch (error: any) {
            if (error.response.data.error === 'New submission not allowed. The form creator is either too broke or too cheap or both for you to fill out this form') {
                setAiMessage('New submission not allowed. The form creator has exceeded their limit, which means they are either too broke or too cheap or both for you to fill out this form'); // Update AI message with response
            } else {
                console.error('Error fetching AI response:', error);
                setAiMessage('Failed to get response from AI.');
            }
        } finally {
            setLoading(false);

        }
    };
    
    return (
        <><div>
            {is_testing ? (
                <>
                    <div className='flex flex-col items-center justify-center'>
                        <div className="text-center text-white p-2.5 w-full bg-red-700 rounded mb-5 absolute top-5">
                                <ul className="flex space-x-2 text-white">
                                    <li>
                                        <button
                                            onClick={() => navigate('/')}
                                            className="underline hover:text-gray-300"
                                        >
                                            Home
                                        </button>
                                    </li>
                                    <li>/</li>
                                    <li>
                                        <button
                                            onClick={() => navigate(`/edit-form/${formId}`)}
                                            className="underline hover:text-gray-300"
                                        >
                                            {formTitle}
                                        </button>
                                    </li>
                                    <li>/</li>
                                    <li>
                                        <button
                                            onClick={() => navigate(`/fill-form/${formId}?test=true`)}
                                            className="underline hover:text-gray-300"
                                        >
                                            Testing
                                        </button>
                                    </li>
                                </ul>
                            You are in Testing Mode, click share on the editing page or user homepage to get a sharable link.
                        </div>
                    </div>
                </>
            ) : (<></>)}
        </div>
        <div className={`w-screen h-screen overflow-auto ${is_testing ? 'h-3/4 w-screen' : 'h-full w-full'} bg-black absolute ${is_testing ? 'top-1/8 left-[-4px]' : 'top-0 left-0'}`}>
        <div className={`flex flex-col text-base sm:text-lg md:text-xl lg:text-2xl items-center justify-center font-mono min-h-full w-full`}>
            <div className='flex grow'></div>

                {!handledInitialSubmit ? (
                    <>
                        {formTitle ? (
                            <div className='text-white text-lg md:text-xl lg:text-2xl'>{formTitle}</div>
                        ) : (
                            <div className="flex justify-center items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        )}
                        <div css={animationStyle} className='flex flex-col items-center relative' onClick={() => {
                            document.getElementById('continue-conversation')?.scrollIntoView({ behavior: 'smooth' });
                        }}>
                            <div className="w-fit opacity-0"><Eyes /></div>
                            <img src="/assets/feistyforms/logo_nofont_white.png" className="h-48" />
                            <div className="w-fit"><Eyes /></div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-center text-base sm:text-lg md:text-xl lg:text-2xl mt-5 m-10 max-w-xl">
                            {loading ? <Eyes /> : <Markdown>{aiMessage}</Markdown>} 
                        </div>
                    </>
                )}

                <form onSubmit={handleSubmit} className="text-center mb-5 flex flex-col">
                    <textarea
                        hidden={loading || !handledInitialSubmit}
                        value={message}
                        placeholder="Type your message here..."
                        enterKeyHint="send"
                        onChange={(e) => setMessage(e.target.value)}
                        ref={inputRef}
                        className="p-2.5 text-base sm:text-lg md:text-xl lg:text-2xl rounded bg-black outline-none text-slate-300"
                        rows={5}
                        cols={window.innerWidth < 480 ? 20 :
                            window.innerWidth < 640 ? 30 :
                                window.innerWidth < 768 ? 40 :
                                    window.innerWidth < 1024 ? 50 :
                                        60 // Default for larger screens
                        }
                        {...({ enterKeyHint: 'send' } as any)} />
                    <div className="flex justify-center w-full">
                        {!handledInitialSubmit ? (


                            conversation_id ? (
                                <>
                                <div id='continue-conversation' className='flex flex-col md:flex-row'>
                                    <button
                                        type="button"
                                        className="bg-slate-700 text-white p-2.5 rounded mt-5 mr-2"
                                        onClick={() => {
                                            setHandledInitialSubmit(true);
                                            handleSubmit();
                                        } }
                                    >
                                        Continue Conversation
                                    </button>
                                    <button
                                        type="button"
                                        className="bg-slate-700 text-white p-2.5 rounded mt-5"
                                        onClick={() => {
                                            localStorage.removeItem('conversation_data'); // Clear both conversation and form data
                                            setHandledInitialSubmit(true);
                                            setConversationId(null); // Clear the state conversation ID
                                            setStartNewConversation(true); // Trigger new conversation after reset
                                        } }
                                    >
                                        Start New
                                    </button>
                                </div>
                                </>
                            ) : (

                                <button
                                    type="submit"
                                    className="bg-slate-700 text-white p-2.5 rounded mt-5"
                                    onClick={() => {
                                        setHandledInitialSubmit(true);
                                        handleSubmit();
                                    } }
                                >
                                    Start
                                </button>

                            )
                        ) : (
                            !loading ? (
                                <>
                                    <button
                                        type="submit"
                                        className="bg-slate-700 text-white p-2.5 rounded mt-5"
                                    >
                                        Send
                                    </button>
                                </>

                            ) : (<></>)
                        )}

                    </div>
                </form>
                <div className='flex grow'></div>
                {handledInitialSubmit && !is_testing ? (
                    <div className="flex justify-center mb-5">
                        <img
                            src="/assets/feistyforms/logo.png"
                            alt="Logo"
                            className="h-24 cursor-pointer"
                            onClick={handleFeistyHome} />
                    </div>) : (<></>)}

            </div>
            {is_testing && submission && form? (<>
                <div className='absolute bottom-0 left-0 w-screen flex flex-col items-center  p-4'>
                    <div className="max-w-full box-border rounded h-1/8 overflow-auto">
                        <table className="min-w-full bg-slate-800 text-center ">
                            <thead>
                                <tr>
                                <th className="py-2 px-4 bg-slate-900 min-w-48">Created at </th>
                                {form.fields.map(field => (
                                    <th key={field.id} className="py-2 px-4 bg-slate-900 min-w-48">{field.name}</th>
                                ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={submission.id}>
                                    <td className="py-2 px-4 border-b border-slate-600 min-w-28">{(new Date(submission.created_at)).toLocaleString()}</td>
                                    {form.fields.map((field: FormField) => {
                                        const submissionField = submission.fields.find((f: SubmissionField) => f.form_field === field.id);
                                        return (
                                            <td key={field.id} className="py-2 px-4 border-b border-slate-600 min-w-48">
                                        {submissionField ? submissionField.value : ''}
                                        </td>
                                    );
                                })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                


            </>) : (<></>)}
        </div>
        </>
    );
};

export default FillForm;




// Combining bounce (twice ) and spin (once) animations
const bounceAndSpin = keyframes`
  0%{ transform: scale(0.5) translateY(0px) rotate(0deg); cubic-bezier(.5, 0.05, 1, .5) }
  25%{ transform: scale(0.5) translateY(0px) rotate(180deg); cubic-bezier(.5, 0.05, 1, .5) }
  50%{ transform: scale(0.5) translateY(0px) rotate(180deg); cubic-bezier(.5, 0.05, 1, .5) }
  75%{ transform: scale(0.5) translateY(0px) rotate(360deg); cubic-bezier(.5, 0.05, 1, .5)}
  100%{ transform: scale(0.5) translateY(0px) rotate(360deg); cubic-bezier(.5, 0.05, 1, .5) }
`


const animationStyle = css` 
  /* Apply the bounce and spin animation */
  animation: ${bounceAndSpin} 10s infinite;
`;

