import React, { useState, useLayoutEffect } from 'react';

interface FloatingTooltipProps {
  message: string;
  targetRef: React.RefObject<HTMLElement>;
}

const FloatingTooltip: React.FC<FloatingTooltipProps> = ({ message, targetRef }) => {
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0 });

  const handleMouseMove = (e: MouseEvent) => {
    setTooltip({ visible: true, x: e.pageX + 10, y: e.pageY + 10 });
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, x: 0, y: 0 });
  };

  useLayoutEffect(() => {
    const targetElement = targetRef.current;
    if (targetElement) {
      targetElement.addEventListener('mousemove', handleMouseMove);
      targetElement.addEventListener('mouseleave', handleMouseLeave);
      console.log('Tooltip event listeners attached to', targetElement);
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('mousemove', handleMouseMove);
        targetElement.removeEventListener('mouseleave', handleMouseLeave);
        console.log('Tooltip event listeners removed');
      }
    };
  }, [targetRef]);

  if (!tooltip.visible) return null;

  return (
    <div
      className="tooltip bg-slate-600 text-greu-100 absolute p-2 rounded"
      style={{
        top: tooltip.y + 10,
        left: tooltip.x -20,
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      {message}
    </div>
  );
};

export default FloatingTooltip;
