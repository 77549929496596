import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Submission, SubmissionField, FormField} from '../components/interfaces'; // Assuming interfaces are in a file named interfaces.ts

const ViewResults: React.FC = () => {
  const { formId } = useParams<{ formId: string }>(); // Get formId from the URL
  const navigate = useNavigate();
  const [form, setForm] = useState<Form | null>(null); // State for the form object
  const [submissions, setSubmissions] = useState<Submission[]>([]); // State for the submissions
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/get-form/${formId}`);
        const data = response.data;

        setForm(data); // Set the entire form object, including fields and submissions
        setSubmissions(data.submissions); // Set the form submissions
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch form results');
        setLoading(false);
      }
    };

    fetchData();
  }, [formId]);

  const convertToCSV = (submissions: Submission[], fields: { id: string, name: string }[]) => {
    const headers = ['Submission ID', ...fields.map(field => field.name)];
    const rows = submissions.map(submission => {
      const row = [submission.id];
      fields.forEach(field => {
        const submissionField = submission.fields.find(f => f.form_field === field.id);
        row.push(submissionField ? submissionField.value : '');
      });
      return row;
    });

    const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    if (!form) return;

    const csvContent = convertToCSV(submissions, form.fields);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `form_results_${formId}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <div className='flex justify-center items-center h-screen w-full'>
    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!form) {
    return <div>No form data available</div>;
  }

  return (
    <div className="p-4 pt-10 h-screen w-full bg-gray-700 text-white flex flex-col rounded items-center justify-center overflow-auto relative ">
        <nav className="p-2 absolute top-0 left-0">
          <ul className="flex space-x-2 text-white">
              <li>
                  <button
                      onClick={() => navigate('/')}
                      className="underline hover:text-gray-300"
                  >
                      Home
                  </button>
              </li>
              <li>/</li>
              <li>
                  <button
                      onClick={() => navigate(`/edit-form/${formId}`)}
                      className="underline hover:text-gray-300"
                  >
                      {form.title}
                  </button>
              </li>
              <li>/</li>
              <li>
                  <button
                      onClick={() => navigate(`/view_results/${formId}`)}
                      className="underline hover:text-gray-300"
                  >
                      Results
                  </button>
              </li>
          </ul>
      </nav>
      <h1 className="text-2xl font-bold mb-4">You got {submissions.length} submissions!</h1>
      <div className="w-full max-w-5/6 overflow-auto bg-slate-800 rounded-xl ">
        <table className="min-w-full bg-slate-800 text-center">
          <thead>
            <tr>
              <th className="py-2 px-4 bg-slate-900 min-w-48">Created at </th>
              {form.fields.map(field => (
                <th key={field.id} className="py-2 px-4 bg-slate-900 min-w-48">{field.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {submissions.map(submission => (
              <tr key={submission.id}>
                <td className="py-2 px-4 border-b border-slate-600">{(new Date(submission.created_at)).toLocaleString()}</td>
                {form.fields.map((field: FormField) => {
                  const submissionField = submission.fields.find((f: SubmissionField) => f.form_field === field.id);
                  return (
                    <td key={field.id} className="py-2 px-4 border-b border-slate-600 min-w-48 max-w-48">
                      {submissionField ? submissionField.value : ''}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button
          onClick={downloadCSV}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Export as CSV
        </button>
      </div>
    </div>
  );
};

export default ViewResults;